import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
const layoutProps = {};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><undefined parentName="p">{`
                `}<div {...{
          "className": "right center square"
        }}>{`
                  `}<img parentName="div" {...{
            "src": "/static/22331f43189295c1b879f639fadd2d18/2bef9/Morne_Corrin_Fane_a_thin_man_in_his_early_fifties_with_shoulder_66785603-9047-49ab-a09c-46cb27943c42.png",
            "srcSet": ["/static/22331f43189295c1b879f639fadd2d18/5ff7e/Morne_Corrin_Fane_a_thin_man_in_his_early_fifties_with_shoulder_66785603-9047-49ab-a09c-46cb27943c42.png 375w", "/static/22331f43189295c1b879f639fadd2d18/1d69c/Morne_Corrin_Fane_a_thin_man_in_his_early_fifties_with_shoulder_66785603-9047-49ab-a09c-46cb27943c42.png 750w", "/static/22331f43189295c1b879f639fadd2d18/2bef9/Morne_Corrin_Fane_a_thin_man_in_his_early_fifties_with_shoulder_66785603-9047-49ab-a09c-46cb27943c42.png 1024w"],
            "width": "400px",
            "className": "right center square"
          }}></img>{`
                  `}<div parentName="div" {...{
            "className": "caption"
          }}>{`Corrin Fane in his study`}</div>{`
                `}</div>{`
                `}</undefined></p>
    <p><strong parentName="p">{`Corrin Fane`}</strong>{` is the founder and director of the `}<a parentName="p" {...{
        "href": "/Saetorim%20Institute",
        "title": "Saetorim Institute"
      }}>{`Saetorim Institute`}</a>{`. He is a middle-aged man with shoulder-length hair, peppered with gray, copper skin, and a cropped gray beard.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      